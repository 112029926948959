import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Collection = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.shopifyCollection.title} description={data.shopifyCollection.description.split('.')[0]} />
      <div css={css`
        padding: 12px;

        @media (min-width: 648px) {
          padding: 12px 0;
        }
      `}>
        <h1
          css={css`
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 13px;
            margin-top: 2px;
            text-transform: uppercase;
          `} 
        >
          {data.shopifyCollection.title}
        </h1>
        <div
          css={css`
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 1rem;

              p {
                  margin-bottom: 4px;
                  &:last-child {
                      margin-bottom: -6px;
                  }
              }

              p.legal {
                  margin-top: 14px;
                  font-size: 16px;
                  line-height: 16px;
                  &:last-child {
                      margin-bottom: -4px;
                  }
              }
          `}
          dangerouslySetInnerHTML={{__html: data.shopifyCollection.descriptionHtml}}
        />
      </div>
      <div css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 12px;
        row-gap: 12px;

        padding: 12px;
        padding-top: 0;
        @media (min-width: 648px) {
          padding: 0;
        }
      `}>
        {data.shopifyCollection.products.map( (product) => (
          <div
            key={product.id}
            css={css`
                
            `}
          >
            <Link to={'/products/'+product.handle}>
              <Img
                fluid={product.images[0].localFile.childImageSharp.fluid}
              />
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Collection

export const query = graphql`
query($handle: String!) {
    shopifyCollection(handle: {eq: $handle}) {
      title
      description
      descriptionHtml
      products {
        id
        title
        shopifyId
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        handle
      }
    }
  }
`